/* stylelint-disable font-family-no-missing-generic-family-keyword */
.yes-no-btn {
  min-width: 250px;
  max-width: 300px;
  height: fit-content;
  min-height: 90px;
  padding: 10px 20px;
  font-family: NotoB;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.yes-btn {
  background-color: #e796b7;
}

.no-btn {
  background-color: #6abfc2;
}

@media screen and (min-width: 501px) and (max-width: 1280px) {
  .yes-no-btn {
    min-width: 19.531vw;
    max-width: 23.438vw;
    height: fit-content;
    min-height: 7.031vw;
    padding: 0.781vw 1.563vw;
    font-family: NotoB;
    font-size: 3.125vw;
    color: #fff;
    border: none;
    border-radius: 0.78vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}

@media screen and (max-width: 500px) {
  .yes-no-btn {
    width: 38.462vw;
    min-width: 0;
    max-width: none;
    min-height: 0;
    padding: 3.846vw 5.128vw;
    font-family: NotoB;
    font-size: 5.641vw;
    color: #fff;
    border: none;
    border-radius: 2.564vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}
