.main {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  border: 12px solid #e796b7;
}

.main-content {
  align-items: center;
  justify-content: center;
}

* {
  word-break: keep-all;
  overflow-wrap: break-word;
}

@media screen and (min-width: 501px) and (max-width: 1280px) {
  .main {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
    border: 0.94vw solid #e796b7;
  }

  .main-content {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .main {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
    border: 12px solid #e796b7;
  }

  .main-content {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1281px) and (max-height: 800px) {
  .main {
    display: block !important;
    padding: 40px 0;
  }
}

@media screen and (min-width: 501px) and (max-width: 1280px) and (max-height: 65.56vw) {
  .main {
    display: block !important;
    padding: 3.13vw 0;
  }
}

@media screen and (max-width: 500px) and (max-height: 162vw) {
  .main {
    display: block !important;
    padding: 5.13vw 0;
  }
}
