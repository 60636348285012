/* stylelint-disable order/properties-order */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
.answer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 720px;
}

.answer-window {
  /* height: 440px; */

  /* overflow: scroll; */
  overflow: hidden;
  border: 5px solid #e796b7;
  border-radius: 10px;
}

.answer {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 680px;
  max-width: 1200px;
  padding: 50px;
}

.answer > p:first-of-type {
  max-width: 1000px;
  overflow-x: hidden;
  font-size: 25px;
  line-height: 30px;
  color: #555;
  text-align: center;
  font-family: NotoM;
  overflow: hidden;
}

.answer-title {
  max-width: 1000px;
  overflow-x: hidden;
  font-family: NotoB;
  font-size: 36px;
  line-height: 50px;
  color: #7b4f29;
  text-align: center;
  overflow: hidden;
}

.answer-description {
  max-width: 1000px;
  overflow-x: hidden;
  font-size: 23px;
  line-height: 32px;
  color: #555;
  text-align: center;
  letter-spacing: 0.05em;
  font-family: NotoM;
  overflow: hidden;
}

.answer-links {
  display: flex;
  column-gap: 100px;
  margin: 0 auto;
}

.answer-link {
  display: flex;
  width: 500px;
  flex-direction: column;
  row-gap: 60px;
  align-items: center;
  padding: 40px 20px;
  border-radius: 7px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.answer-link1 {
  background-color: #65dc96;
}

.answer-link2 {
  background-color: #f1c1d5;
}

.answer-link > p {
  width: 400px;
  font-family: NotoB;
  font-size: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
}

.answer-link div p {
  display: flex;
  column-gap: 15px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  font-family: NotoM;
}

.answer-link div {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  word-break: normal;
}

.answer-link div img {
  width: 23px;
  height: fit-content;
}

@media screen and (min-width: 501px) and (max-width: 1280px) {
  .answer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 62.56vw;
  }

  .answer-window {
    /* height: 34.38vw; */

    /* overflow: scroll; */
    overflow: hidden;
    border: 5px solid #e796b7;
    border-radius: 2.564vw;
  }

  .answer {
    display: flex;
    flex-direction: column;
    row-gap: 4.688vw;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 53.125vw;
    max-width: 93.75vw;
    padding: 3.906vw;
  }

  .answer > p:first-of-type {
    max-width: 78.125vw;
    font-size: 1.953vw;
    line-height: 2.344vw;
    color: #555;
    text-align: center;
  }

  .answer-title {
    max-width: 78.125vw;
    font-family: NotoB;
    font-size: 2.813vw;
    line-height: 3.906vw;
    color: #7b4f29;
    text-align: center;
  }

  .answer-description {
    max-width: 78.125vw;
    font-size: 1.797vw;
    line-height: 2.5vw;
    color: #555;
    text-align: center;
    letter-spacing: 0.05em;
  }

  .answer-links {
    display: flex;
    column-gap: 5.813vw;
    margin: 0 auto;
  }

  .answer-link {
    display: flex;
    flex-direction: column;
    width: 36.3vw;
    row-gap: 4.688vw;
    align-items: center;
    padding: 3.125vw 3.516vw;
    border-radius: 0.55vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .answer-link1 {
    background-color: #65dc96;
  }

  .answer-link2 {
    background-color: #f1c1d5;
  }

  .answer-link > p {
    width: 31.25vw;
    font-family: NotoB;
    font-size: 2.344vw;
    color: #fff;
    text-align: center;
    letter-spacing: 0.1em;
  }

  .answer-link div p {
    display: flex;
    column-gap: 1.172vw;
    align-items: center;
    justify-content: center;
    font-size: 1.353vw;
    color: #fff;
  }

  .answer-link div {
    display: flex;
    flex-direction: row;
    column-gap: 0.781vw;
    align-items: center;
    justify-content: center;
    word-break: normal;
  }

  .answer-link div img {
    width: 1.797vw;
    height: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .answer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 156.41vw;
  }

  .answer-window {
    /* height: 92.31vw; */

    /* overflow: scroll; */
    overflow: hidden;
    border: 5px solid #e796b7;
    border-radius: 2.564vw;
  }

  .answer {
    display: flex;
    flex-direction: column;
    row-gap: 7.692vw;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: 76.923vw;
    max-width: 82.051vw;
    padding: 5.128vw 2.564vw;
  }

  .answer > p:first-of-type {
    max-width: 69.231vw;
    font-size: 4.103vw;
    line-height: 6.41vw;
    color: #555;
    text-align: center;
  }

  .answer-title {
    max-width: 69.231vw;
    font-family: NotoB;
    font-size: 6.41vw;
    line-height: 9.744vw;
    color: #7b4f29;
    text-align: center;
  }

  .answer-description {
    max-width: 69.231vw;
    font-size: 3.9vw;
    line-height: 6.154vw;
    color: #555;
    text-align: center;
    letter-spacing: 0.05em;
  }

  .answer-links {
    display: flex;
    flex-direction: column;
    row-gap: 8.974vw;
    margin: 0 auto;
  }

  .answer-link {
    display: flex;
    flex-direction: column;
    width: 80vw;
    row-gap: 6.41vw;
    align-items: center;
    padding: 2.564vw;
    border-radius: 1.795vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  .answer-link1 {
    background-color: #65dc96;
  }

  .answer-link2 {
    background-color: #f1c1d5;
  }

  .answer-link > p {
    width: 76.923vw;
    font-family: NotoB;
    font-size: 4.103vw;
    color: #fff;
    text-align: center;
    letter-spacing: 0.1em;
  }

  .answer-link div p {
    display: flex;
    column-gap: 2.564vw;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    color: #fff;
  }

  .answer-link div {
    display: flex;
    flex-direction: row;
    column-gap: 2.564vw;
    align-items: center;
    justify-content: center;
    word-break: normal;
  }

  .answer-link div img {
    width: 5vw;
    height: auto;
  }
}
