.question-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 600px;
}

.y-n-btns {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  width: fit-content;
}

@media screen and (min-width: 501px) and (max-width: 1280px) {
  .question-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 46.88vw;
  }

  .y-n-btns {
    display: flex;
    flex-direction: row;
    column-gap: 3.125vw;
    width: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .question-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 128.21vw;
  }

  .y-n-btns {
    display: flex;
    flex-direction: row;
    column-gap: 6.41vw;
    width: fit-content;
  }
}
