/* stylelint-disable font-family-no-missing-generic-family-keyword */
.question {
  max-width: 1400px;
  margin: 0 auto;
  font-family: NotoB;
  font-size: 50px;
  line-height: 80px;
  color: #9f7f63;
  text-align: center;
  white-space: pre-wrap;
}

@media screen and (min-width: 501px) and (max-width: 1280px) {
  .question {
    max-width: 109.375vw;
    margin: 0 auto;
    font-family: NotoB;
    font-size: 3.906vw;
    line-height: 6.25vw;
    color: #9f7f63;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .question {
    width: 80vw;
    max-width: 83.333vw;
    margin: 0 auto;
    overflow-x: hidden;
    font-family: NotoB;
    font-size: 6.41vw;
    line-height: 10.256vw;
    color: #9f7f63;
    text-align: center;
  }
}
