/* stylelint-disable font-family-no-missing-generic-family-keyword */
@font-face {
  font-family: NotoB;
  src: url(./font/NotoSansJP-Bold.woff);
}

@font-face {
  font-family: NotoM;
  src: url(./font/NotoSansJP-Medium.woff);
}

body {
  margin: 0;
  font-family: NotoM;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}
